import {
  Box,
  CircularProgress,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";

const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));

function App() {
  return (
    <Router>
      <Box>
        <Navbar />
        <Toolbar />
        <React.Suspense
          fallback={
            <Box sx={{ display: "flex", justifyContent: "center", py: 32 }}>
              <CircularProgress />
            </Box>
          }
        >
          <Box sx={{ minHeight: "calc(100vh - 134px)" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Box>
          <Box component="footer">
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 3,
                px: 14,
                "@media (max-width: 600px)": { px: 2 },
              }}
            >
              <Typography variant="body2">
                @ 2024. All rights reserved
              </Typography>
            </Box>
          </Box>
        </React.Suspense>
      </Box>
    </Router>
  );
}

export default App;
